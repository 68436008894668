import React from 'react';
import {
  Button, Card, Col, Row, Statistic, Typography,
} from 'antd';
import { BaseLayout } from '../../components/layout/base/base-layout';
import { HelpCard } from '../../components/cards/help-card/help-card';
import { SEO } from '../../components/layout/seo';
import css from './about.module.less';

const {
  Title,
  Paragraph,
} = Typography;
const { Meta } = Card;

const CSRPage = () => (
  <BaseLayout>
    <SEO title="Community and Corporate Social Responsibility" />
    <Row gutter={[32, 32]}>
      <Col flex="1 1 500px">
        <Title level={2}>
          Our Community and Corporate Social Responsibility
        </Title>
        <Paragraph>
          Here at Akommo, forming part of our Barcelona community is of the highest importance. But we also work hard
          to foster diversity, eco-friendly habits and awareness about the society's impact on our planet and
          environment. Our commitment to Corporate Social Responsibility (CSR) has grown over the years in many ways
          through our involvement in various Barcelona social projects and through the implementation of ethical
          standards and behaviour. Choosing Akommo as your corporate accommodation and venue provider implies that you
          support and share the same outward-focused values and ethics.
        </Paragraph>
        <Paragraph>
          By incorporating CSR into our company strategy and policies, our objective and mission is to positively
          impact the event industry by showing that corporate events can also be socially responsible. We understand
          what it's like to be a small organisation, and we understand the limitations that they face. But, when all
          of us make small changes together, we will have a bigger impact and make the world a better place for
          everyone. We aim to support small and local organisations by inspiring as many companies as possible to get
          involved in their communities too. We're dedicated to making the event industry more socially responsible,
          more inspirational, and more enjoyable.
        </Paragraph>
      </Col>
      <Col flex="0 1 200px">
        <HelpCard />
      </Col>
    </Row>
    <Row gutter={[32, 32]} className={css.csrBackground}>
      <Col flex={1}>
        <Card>
          <Statistic
            title="Funds raised"
            value={3021}
            suffix="€"
          />
        </Card>
      </Col>
      <Col flex={1}>
        <Card>
          <Statistic
            title="Akommo Volunteers"
            value={7}
          />
        </Card>
      </Col>
      <Col flex={1}>
        <Card>
          <Statistic
            title="CSR dedicated time"
            value={20}
            suffix="%"
          />
        </Card>
      </Col>
    </Row>
    <Row gutter={[32, 32]} justify="space-around">
      <Col flex="0 1 430px">
        <Card
          cover={(
            <img
              alt="example"
              src="https://akommo.com/static/img/akommo_csr_1.jpg"
            />
          )}
          actions={[
            <Button size="large">
              <a href="https://akommo.com/blog/barcelona/barcelona-csr-5-reasons-to-go-local">
                Read
                More on our Blog
              </a>
            </Button>,
          ]}
        >
          <Meta
            title="Akommo Social and Ethical Values"
            description="Akommo strives to be Europe’s most enjoyable corporate event company and this implies many different factors. We work on specific details, from choosing ethical, trustworthy and reliable partners, to selecting accommodation and venues where standards are respected. We have also developped a green commitment and work on reducing our ecological footprint by also sharing good practices."
          />
        </Card>
      </Col>
      <Col flex="0 1 430px">
        <Card
          cover={(
            <img
              alt="example"
              // TODO download the images and replace this with gatsby image component
              src="https://akommo.com/static/img/csr_2.png"
            />
          )}
          actions={[
            <Button size="large">
              <a href="https://akommo.com/blog/barcelona/barcelona-csr-5-reasons-to-go-local">
                Sponsor
                the Magic Line
              </a>
            </Button>,
          ]}
        >
          <Meta
            title="Magic Line Partnership"
            description="Akommo strives to be Europe’s most enjoyable corporate event company and this implies many different factors. We work on specific details, from choosing ethical, trustworthy and reliable partners, to selecting accommodation and venues where standards are respected. We have also developped a green commitment and work on reducing our ecological footprint by also sharing good practices."
          />
        </Card>
      </Col>
      <Col flex="0 1 430px">
        <Card
          cover={(
            <img
              alt="example"
              src="https://akommo.com/static/img/csr_5.jpg"
            />
          )}
          actions={[
            <Button size="large"><a href="https://pwnbarcelona.net/">Join us at PWN</a></Button>,
          ]}
        >
          <Meta
            title="Local business support & Women Network"
            description="Akommo strives to be Europe’s most enjoyable corporate event company and this implies many different factors. We work on specific details, from choosing ethical, trustworthy and reliable partners, to selecting accommodation and venues where standards are respected. We have also developped a green commitment and work on reducing our ecological footprint by also sharing good practices."
          />
        </Card>
      </Col>
    </Row>
  </BaseLayout>
);

export default CSRPage;
